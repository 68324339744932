<template>
  <div>
    <div class="mass-action-settings">

      <card-create-templates
        v-if="$canAndRulesPass(user, $permissions.CARD_CREATE_TEMPLATES.LIST)"
        style=""
        :card="$refs.cardEditForm ? $refs.cardEditForm.card : {}"
        :sections="$refs.cardEditForm ? $refs.cardEditForm.sections : []"
        :stages="$refs.cardEditForm ? $refs.cardEditForm.stages : {}"
        @template-selected="setSettingsFromTemplate"
      ></card-create-templates>

      <card-edit-form
        ref="cardEditForm"
        style="width: 50%"
      ></card-edit-form>


      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите создать карты?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Создать
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";
import CardEditForm from "@/components/cards/CardEditForm.vue";
import CardCreateTemplates from "@/components/cards/CardCreateTemplates.vue";
import {mapGetters} from "vuex";

export default {
  name: "user-create-card-action",
  mixins: [ActionTemplateMixin],
  components: {CardCreateTemplates, CardEditForm, AddUsersDialog, MassActionResult},

  computed: {
    ...mapGetters(['user']),
    actionButtonDisabled() {
      return false;
      return !this.actionData.chief_type
        || !this.actionData.user_id;
    }
  },
  watch: {

  },
  data() {
    return {
    }
  },
  methods: {
    setSettingsFromTemplate(template) {
      this.$refs.cardEditForm.setSettingsFromTemplate(template);
    },

    doAction(){
      let stagesArray = Object.keys(this.$refs.cardEditForm.stages).map(key => this.$refs.cardEditForm.stages[key]);
      this.$emit('do-action', {
        year: this.$refs.cardEditForm.card.year,
        period: this.$refs.cardEditForm.card.period,
        period_index: this.$refs.cardEditForm.card.period_index,
        stages: stagesArray.filter(stage => stage.enabled),
        sections: this.$refs.cardEditForm.sections,
        info: this.$refs.cardEditForm.card.info,
      })
    }
  },
}
</script>
